<template>
    <div
        :style="{
            marginTop: heightTop,
        }"
    >
        <div class="empty">
            <div>
                <img
                    :src="emptyUrl"
                    alt=""
                />
            </div>
            <div class="nullText">暂无内容</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "EmptyView",
    props: ["height"],
    computed: {
        emptyUrl() {
            return require("@/assets/images/null.png");
        },
        heightTop() {
            return this.height / 2 - 120  + "px";
        },
    },
};
</script>

<style lang="scss" scoped>
.empty {
    margin-top: 10rem;

    div {
        width: fit-content;
        margin: 0 auto;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 16px;
        color: #808387;
        line-height: 36px;
    }
}
</style>