<template lang="html">
    <div class="editor">
        <div ref="toolbar" class="toolbar" />
        <div ref="editor" class="text"></div>
    </div>
</template>

<script>
    import E from 'wangeditor'
    import { uploadAction } from "@/libs/settings";
    import { getToken } from "../../../../../manager/src/libs/auth";

    export default {
        name: 'RichView',
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String,
                default: ''
            },
            height: {
                type: String,
                default: '400px'
            }
        },
        data() {
            return {
                editor: null,
                info_: null,
                insertImgFn: ''
            }
        },
        watch: {
            value: function (value) {
                if (value !== this.editor.txt.html()) {
                    this.editor.txt.html(this.value)
                }
            }
        },
        mounted() {
            this.setEditor()
            this.editor.txt.html(this.value)
        },
        methods: {
            /**
             * @Description: 创建富文本显示
             * @DoWhat: 
             * @UseScenarios: 
             * @Attention: 
             * @Author: 周浩
             * @Date: 2024-07-08 16:27:13
             */            
            async setEditor() {
                this.editor = new E(this.$refs.toolbar, this.$refs.editor)
                this.editor.config.menus = []
                this.editor.config.placeholder = ""
                this.editor.config.showLinkImg = false
                this.editor.config.zIndex = 99
                // 创建富文本编辑器
                this.editor.create()
                this.editor.disable();
                return
            },
        }
    }
</script>

<style lang="scss" scoped>
    .editor {
        // border: 1px solid #ccc;

        ::-webkit-scrollbar {
            width: 4px;
            height: 10px;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #C0C4CC;
        }

        ::-webkit-scrollbar-track {
            border-radius: 0;
            background: #f2f6fe;
        }
    }

    /deep/ .w-e-toolbar {
        background-color: #f2f6fe;
    }

    

    .cropper-content {
        display: flex;
        justify-content: center;

        .cropper {
            width: 500px;
            height: 300px;
        }

        .vue-cropper {
            background: rgb(155, 152, 152);
        }
    }
</style>
