<template>
    <div>
        <div
            v-for="item in list"
            :key="item.id"
        >
            <div
                style="padding: 0px 24px;"
                class="menu-list"
                @click="handClick(item)"
            >
                <div
                    class="name"
                    :style="{paddingLeft: padding * isLeav + 'px'}"
                >
                    {{ item.name }}
                </div>
                <div class="line"></div>
                <div class="time">{{ item.updateTime }}</div>
            </div>
            <template v-if="item.children">
                <menu-list
                    :list="item.children"
                    :isLeav="isLeav + 1"
                    padding="30"
                />
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: "MenuList",
    props: {
        list: {
            type: Array,
            default: [],
        },
        padding: {
            type: String,
            default: "0px",
        },
        isLeav: {
            type: Number,
            default: 0,
        },
    },
    methods: {
        /**
         * @Description: 点击目录
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-05 17:22:32
         */
        handClick(item) {
            this.$eventDispatch("handClick", item);
        },
    },
};
</script>

<style lang="scss" scoped>
.menu-list {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 36px;
    color: #2b2f33;
    .time {
        flex-shrink: 0;
    }
    .line {
        flex: 1;
        height: 1px;
        border-top: 1px dashed #9da2a6;
        margin: 0px 10px;
    }
    .name {
        flex-shrink: 0;
    }
}
.menu-list:hover {
    background: #f7f7f7;
}
</style>