<!-- 帮助中心 systemHelp:list -->
<template>
    <div class="main">
        <div
            class="menu"
            :class="{menuNull: !isShowMenu}"
        >
            <div
                class="menu_title padding"
                style="margin-top: 10px"
            >
                鑫考智慧校园产品使用手册
            </div>
            <div
                class="seach padding"
                style="margin-top: 10px"
            >
                <el-input
                    ref="search"
                    placeholder="请输入搜索内容"
                    prefix-icon="el-icon-search"
                    v-model="filterText"
                    clearable
                    @click="focusBtn"
                >
                </el-input>
            </div>
            <div
                style="margin-top: 10px"
                class="paddingLeft ding home_shou"
                :class="{active: activeId == 0}"
                @click="homeClick"
            >
                首页
            </div>
            <div class="paddingLeft ding mul">
                <div>目录</div>
                <div>
                    <div
                        style="cursor: pointer"
                        @click="handClick"
                    >
                        <i
                            v-if="allOpen"
                            class="el-icon-arrow-down"
                        ></i>
                        <i
                            v-else
                            class="el-icon-arrow-right"
                        ></i>
                    </div>
                </div>
            </div>
            <div class="tree">
                <el-tree
                    @node-click="handleNodeClick"
                    class="filter-tree"
                    :data="catalogueTree"
                    :props="defaultProps"
                    default-expand-all
                    :filter-node-method="filterNode"
                    node-key="id"
                    ref="tree"
                    :indent="0"
                >
                    <div
                        class="custom-tree-node"
                        style="padding-left: 16px"
                        @click="activeNode(node, data)"
                        slot-scope="{node, data}"
                        :class="{active: node.data.id == activeId}"
                    >
                        <div
                            :style="{
                                'padding-left': (node.level - 1) * 16 + 'px',
                            }"
                        >
                            {{ node.label }}
                        </div>
                        <div>
                            <div v-if="node.childNodes.length">
                                <i
                                    v-if="node.expanded"
                                    class="el-icon-arrow-down"
                                ></i>
                                <i
                                    v-else
                                    class="el-icon-arrow-right"
                                ></i>
                            </div>
                        </div>
                    </div>
                </el-tree>
            </div>
        </div>
        <div
            class="context"
            ref="context"
        >
            <HelpList
                v-if="!activeId"
                :loadingShow="loadingShow"
                :data="catalogueTree"
                @handClick="handClickMenu"
                :height="contextHeight"
            ></HelpList>
            <RichTextDisplay
                :loadingShow="loadingShow"
                :title="contextTitle"
                :contextData="contextData"
                :height="contextHeight"
                v-else
            ></RichTextDisplay>
            <!-- <div v-html="ss"></div>
            <div>{{ ss }}</div>
            <button
                style="width: 100px; height: 40px"
                @click="htmeText"
            >
                生成富文本
            </button>
            <RichEditor v-model="t_html"></RichEditor> -->
        </div>

        <div
            class="tipl"
            :class="{weiz: !isShowMenu}"
            @click="showMenuClick"
        >
            <i
                v-if="isShowMenu"
                class="el-icon-arrow-left"
            ></i>
            <i
                v-else
                class="el-icon-arrow-right"
            ></i>
        </div>
    </div>
</template>

<script>
import HelpList from "./systemHelp/HelpList.vue";
import RichTextDisplay from "./systemHelp/RichTextDisplay.vue";
import RichEditor from "./systemHelp/RichEditor.vue";
export default {
    name: "systemHelp",
    components: {
        HelpList,
        RichTextDisplay,
        RichEditor,
    },
    watch: {
        filterText(val) {
            this.fifterData = [];
            this.$refs.tree.filter(val);
        },
        fifterData(val) {
            this.activeId = "";
            if (this.fifterData.length > 0) {
                let item = this.fifterData[0];
                this.activeId = item.id;
                this.contextTitle = item.name;
                this.getCatalogueContext(item.id);
            }
        },
    },
    data() {
        return {
            t_html: "",
            ss: "",
            isFocus: false,
            loadingShow: false,
            title: "",
            oldTreeData: [],
            catalogueTree: [],
            isShowMenu: true,
            filterText: "",
            activeId: 0,
            allOpen: true,
            data: [],
            defaultProps: {
                children: "children",
                label: "name",
            },
            contextTitle: "",
            contextData: "",

            fifterData: [],
            contextHeight: "",
        };
    },
    mounted() {
        this.getCatalogueList();

        this.$nextTick(() => {
            let height = this.$refs.context.clientHeight;
            this.contextHeight = height;
        });
    },
    methods: {
        htmeText() {
            console.debug(this.t_html);

            let aa = JSON.parse(JSON.stringify(this.t_html));
            aa = aa.replace(
                /\<table/g,
                `<table style='border:.5px solid #CCCCCC'  border="0" width="100%" cellpadding="0" cellspacing="0"`,
            );
            aa = aa.replace(
                /\<td/g,
                "<td style='border:.5px solid #CCCCCC;min-height: 30px;padding: 5px'",
            );
            aa = aa.replace(
                /\<th/g,
                "<th style='border:.5px solid #CCCCCC;min-height: 30px;padding: 5px; background-color: #F1F1F1'",
            );

            this.ss = aa;
        },
        /**
         * @Description: 点击目录折叠按钮
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-05 16:57:36
         */
        showMenuClick() {
            this.isShowMenu = !this.isShowMenu;
        },
        /**
         * @Description: 点击首页
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-05 16:57:36
         */
        homeClick() {
            this.isFocus = false;
            this.activeId = 0;
            // 调用拿取首页目录接口
        },
        /**
         * @Description: 筛选
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-05 16:58:10
         */
        filterNode(value, data) {
            if (!value) return true;

            if (data.name.indexOf(value) !== -1) {
                this.fifterData.push(data);
            }

            return data.name.indexOf(value) !== -1;
        },
        /**
         * @Description: 点击目录触发
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-05 16:58:10
         */
        handleNodeClick(data, node, components) {
            this.isFocus = false;
            this.activeId = data.id;
            this.watchNode();
            this.$refs.tree.setCheckedKeys([data.id], true);

            this.contextTitle = data.name;
            this.getCatalogueContext(data.id);
        },
        /**
         * @Description: 展开或折叠
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-05 16:58:10
         */
        handClick() {
            this.allOpen = !this.allOpen;
            var treeNodeList = this.$refs.tree.store._getAllNodes();
            for (let i = 0; i < treeNodeList.length; i++) {
                treeNodeList[i].expanded = this.allOpen;
            }
        },
        /**
         * @Description: 监听是否全部展开
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-05 17:01:09
         */
        watchNode() {
            let isAllOpen = true;
            var treeNodeList = this.$refs.tree.store._getAllNodes();
            for (let i = 0; i < treeNodeList.length; i++) {
                if (!treeNodeList[i].expanded) {
                    isAllOpen = false;
                }
            }
            this.allOpen = isAllOpen;
        },
        /**
         * @Description: 首页目录点击触发
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-05 17:01:55
         */
        handClickMenu(item) {
            let id = item.id;
            this.activeId = id;

            var treeNodeList = this.$refs.tree.store._getAllNodes();

            let newData = null;
            let haveArr = [];

            for (let i = 0; i < treeNodeList.length; i++) {
                if (treeNodeList[i].data.id == id) {
                    newData = treeNodeList[i];
                }
            }
            haveArr.push(newData.id);
            this.filt(newData.parent, haveArr);

            haveArr.forEach((element) => {
                let id = element;
                for (let i = 0; i < treeNodeList.length; i++) {
                    if (treeNodeList[i].id == id) {
                        treeNodeList[i].expanded = true;
                    }
                }
            });

            this.handleNodeClick(item);
        },
        /**
         * @Description: 点击树状数据触发
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-05 16:58:10
         */
        activeNode(node, data) {},
        /**
         * @Description: 收集选中哪几级树状结构
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-05 17:02:29
         */
        filt(data, haveArr) {
            if (!data.parent) return;
            haveArr.push(data.id);
            this.filt(data.parent, haveArr);
        },
        /**
         * @Description: 拿取目录结构
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-08 09:57:03
         */
        getCatalogueList() {
            this.loadingShow = true;
            this._get("/school/systemOperatingGuide/info").then((res) => {
                if (res.data.code == "200") {
                    let title = res.data.data.name;
                    let catalogueTree = res.data.data.guideIndexTree;
                    this.oldTreeData = JSON.parse(
                        JSON.stringify(catalogueTree),
                    );
                    this.title = title;
                    this.catalogueTree = catalogueTree;
                } else {
                    this.$message.error(res.data.msg);
                }

                this.loadingShow = false;
            });
        },
        /**
         * @Description: 拿取章节内容
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 周浩
         * @Date: 2024-07-08 09:57:03
         */
        getCatalogueContext(id) {
            this.loadingShow = true;
            this.contextData = "";
            this._get(`/school/systemOperatingGuide/content/${id}`).then(
                (res) => {
                    if (res.data.code == "200") {
                        this.contextData = res.data.data
                            ? res.data.data.content
                            : "";
                    } else {
                        this.$message.error(res.data.msg);
                    }
                    this.loadingShow = false;

                    this.$refs.search.focus();
                },
            );
        },
        focusBtn() {
            this.isFocus = true;
            this.$refs.search.focus();
        },
    },
};
</script>

<style lang="scss" scoped>
.el-tree .el-tree-node__content:hover {
    background-color: #409eff;
}

.el-tree .el-tree-node__children .el-tree-node__content:hover {
    background-color: #409eff;
}

.el-tree .el-tree-node__content.is-current {
    background-color: #409eff;
}

.padding {
    padding: 0px 16px;
}
.paddingLeft {
    padding-left: 16px;
}
.main {
    position: relative;
    height: calc(100vh - 120px);
    overflow: hidden;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    width: calc(100% - 1px);
    .menu {
        transition: all 0.3s;
        flex-shrink: 0;
        width: 250px;
        border-right: 1px solid #e1e3e6;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .menu_title {
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 18px;
            color: #2b2f33;
            line-height: 36px;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            flex-shrink: 0;
        }

        .seach {
            flex-shrink: 0;
        }

        .home_shou {
            flex-shrink: 0;
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
        }

        .home_shou:hover {
            background: #f7f7f7;
        }

        .ding {
            height: 36px;
            font-family: Microsoft YaHei;
            font-size: 14px;
            color: #2b2f33;
            line-height: 36px;
        }
        .mul {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 14px;
            padding-right: 16px;
            white-space: nowrap;
            overflow: hidden;
        }
        .tree {
            flex: 1;
            overflow: auto;
        }
    }
    .menuNull {
        width: 0px !important;
    }
    .context {
        flex: 1;
        overflow: hidden;
        overflow-y: auto;
        padding: 28px;
        position: relative;
    }

    .tipl {
        position: absolute;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        overflow: hidden;
        top: 100px;
        left: 235px;
        background-color: #ffffff;
        border: 1px solid #e6e8eb;
        text-align: center;
        line-height: 28px;
        cursor: pointer;
        transition: all 0.3s;
    }
    .weiz {
        left: 30px;
    }
}
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    overflow: hidden;
    & > div {
        height: 36px;
        line-height: 36px;
    }
    & > div:nth-of-type(1) {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    & > div:nth-of-type(2) {
        flex-shrink: 0;
        width: 30px;
        text-align: center;
    }
}

.active {
    color: #3c7fff !important;
    background-color: #e3eefc !important;
}
::v-deep {
    .el-tree-node__expand-icon {
        display: none !important;
    }
    .el-tree-node__content {
        height: 36px;
        font-family: Microsoft YaHei;
        font-size: 14px;
        color: #2b2f33;
    }
    .el-tree-node__content:hover {
        background: #f7f7f7;
    }

    .el-tree .el-tree-node__content:hover {
        background-color: #f7f7f7 !important;
    }

    .el-tree .el-tree-node__content .is-current {
        background-color: #409eff !important;
    }
}
</style>