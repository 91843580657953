<template>
    <div style="padding: 0 40px">
        <div
            class="container"
            style="padding: 0px 24px"
        >
            <div>
                <img
                    :src="shouce"
                    alt=""
                    srcset=""
                />
            </div>
            <div class="title_conte">鑫考智慧校园产品使用手册</div>
        </div>
        <div style="padding: 20px 0px">
            <Loading
                v-if="loadingShow"
                :loadingShow="loadingShow"
            ></Loading>
            <MenuLIst
                v-else
                :list="data"
            ></MenuLIst>
            <EmptyView v-if="!data.length"></EmptyView>
        </div>
    </div>
</template>

<script>
import MenuLIst from "./MenuLIst.vue";
import {Loading} from "common-local";
import EmptyView from "./EmptyView.vue";
export default {
    name: "HelpList",
    props: {
        data: {
            type: Array,
            default: [],
        },
        loadingShow: {
            type: Boolean,
            default: true,
        },
    },
    components: {MenuLIst, Loading,EmptyView},
    computed: {
        shouce() {
            return require("@/assets/images/shouce.png");
        },
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    align-items: center;
    img {
        width: 26px;
        height: 30px;
    }
    & > div:nth-of-type(1) {
        width: 26px;
        height: 30px;
        margin-right: 12px;
    }
    & > div:nth-of-type(2) {
        flex: 1;
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 30px;
        color: #2b2f33;
        line-height: 36px;
        white-space: nowrap;
        overflow: hidden;
    }
}
</style>