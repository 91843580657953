<template lang="html">
    <div class="editor">
        <div ref="toolbar" class="toolbar" />
        <div ref="editor" class="text" :style="{'height': `${height}`}"></div>
    </div>
</template>

<script>
    import E from 'wangeditor'
    import { uploadAction } from "@/libs/settings";
    import { getToken } from "../../../../../manager/src/libs/auth";

    export default {
        name: 'RichEditor',
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: String,
                default: ''
            },
            placeholder: {
                type: String,
                default: ''
            },
            height: {
                type: String,
                default: '600px'
            }
        },
        data() {
            return {
                editor: null,
                info_: null,
                insertImgFn: ''
            }
        },
        watch: {
            value: function (value) {
                if (value !== this.editor.txt.html()) {
                    this.editor.txt.html(this.value)
                }
            }
        },
        mounted() {
            this.setEditor()
            this.editor.txt.html(this.value)
        },
        methods: {
            async setEditor() {
                this.editor = new E(this.$refs.toolbar, this.$refs.editor)
                this.editor.config.menus = [
                    'head', // 标题
                    'bold', // 粗体
                    'fontSize', // 字号
                    'fontName', // 字体
                    'italic', // 斜体
                    'underline', // 下划线
                    'strikeThrough', // 删除线
                    'foreColor', // 文字颜色
                    'backColor', // 背景颜色
                    'link', // 插入链接
                    'list', // 列表
                    'justify', // 对齐方式
                    'quote', // 引用
                    'emoticon', // 表情
                    'image', // 插入图片
                    'video', // 视频
                    'table', // 表格
                    'code', // 插入代码
                    'undo', // 撤销
                    'redo', // 重复
                    'fullscreen', // 全屏
                    
                ]

                let uploadAction = `https://cloudcampus.xinkaoyun.com:40003/api/school/common/uploadFile`
                this.editor.config.placeholder = "请输入"
                this.editor.config.colors = ["#417FF9", "#FF3A3A", "#000", "#4d80bf"];
                this.editor.config.showLinkImg = false
                this.editor.config.height = 400
                this.editor.config.zIndex = 99
                // 配置上传视频
                this.editor.config.uploadVideoServer = uploadAction
                this.editor.config.uploadVideoMaxSize = undefined
                this.editor.config.withCredentials = true
                this.editor.config.uploadVideoName = 'file'
                this.editor.config.uploadVideoHeaders = {
                    'Authorization': getToken()
                }
                this.editor.config.uploadVideoHooks = {
                    customInsert: (insertVideoFn, result) => {
                        if (result.code === '200') {
                            insertVideoFn(result.data.url)
                        }
                    }
                }
                // 配置上传图片
                this.editor.config.uploadImgServer = uploadAction
                this.editor.config.uploadImgMaxSize = 5 * 1024 * 1024
                this.editor.config.withCredentials = true
                this.editor.config.uploadFileName = 'file'
                this.editor.config.uploadImgHeaders = {
                    'Authorization': getToken()
                }
                this.editor.config.uploadImgHooks = {
                    customInsert: (insertImgFn, result) => {
                        if (result.code === '200') {
                            insertImgFn(result.data.url)
                        }
                    }
                }
                this.editor.config.onchange = (html) => {
                    this.info_ = html // 绑定当前逐渐地值
                    this.$emit('change', this.info_) // 将内容同步到父组件中
                }
                // 创建富文本编辑器
                this.editor.create()
                return
            },
        }
    }
</script>

<style lang="scss" scoped>
    .editor {
        border: 1px solid #ccc;

        ::-webkit-scrollbar {
            width: 4px;
            height: 10px;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #C0C4CC;
        }

        ::-webkit-scrollbar-track {
            border-radius: 0;
            background: #f2f6fe;
        }
    }

    /deep/ .w-e-toolbar {
        background-color: #f2f6fe;
    }

    .text {
        border-top: 1px solid #ccc;
        overflow-y: auto;
        min-height: 100px;
    }

    .cropper-content {
        display: flex;
        justify-content: center;

        .cropper {
            width: 500px;
            height: 300px;
        }

        .vue-cropper {
            background: rgb(155, 152, 152);
        }
    }
</style>
