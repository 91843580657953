<template>
    <div class="richText">
        <div class="rich-title">{{ title.split(" ")[1] }}</div>

        <div v-if="!loadingShow">
            <div v-if="contextData">
                <!-- <RichView v-model="contextData"></RichView> -->
                <div
                    id="richText_html"
                    v-html="contextData"
                ></div>
            </div>
            <EmptyView :height="height" v-else></EmptyView>
        </div>
        <Loading
            v-else
            :loadingShow="loadingShow"
        ></Loading>

        <el-image
            v-show="false"
            ref="preview"
            class="hideImgDiv"
            :preview-src-list="[url]"
        ></el-image>
    </div>
</template>

<script>
import RichEditor from "./RichEditor.vue";
import RichView from "./RichView.vue";
import EmptyView from "./EmptyView.vue";
import {Loading} from "common-local";

export default {
    name: "RichTextDisplay",
    components: {RichEditor, RichView, EmptyView, Loading},
    props: {
        title: {
            type: String,
            default: "",
        },
        contextData: {
            type: String,
            default: "",
        },
        loadingShow: {
            type: Boolean,
            default: false,
        },
        height:{
            type: Number,
            default: 0,
        }
    },
    methods: {
        htmeText() {
            console.debug(this.t_html);
        },
        imageBtn(e, event) {
            console.debug(e, event);
            let node = e.target;
            let src = node.getAttribute("src");
            console.debug(src);

            this.url = src;

            this.$refs.preview.clickHandler();
        },
    },
    data() {
        return {
            t_html: "",
            contextData1: ``,
            url: "",
        };
    },
    watch: {
        t_html: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {},
        },
        contextData() {
            this.$nextTick(() => {
                let node = document.getElementById("richText_html");
                if (node) {
                    let p = node.getElementsByTagName("img");
                    for (let i = 0; i < p.length; i++) {
                        p[i].onclick = this.imageBtn;
                    }
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
table {
    border: 1px solid black !important;
}
td {
    border: 1px solid black !important;
}
.richText {
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px 64px;
    .rich-title {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 24px;
        color: #2b2f33;
        line-height: 36px;
        margin-bottom: 10px;
    }
}
#richText_html {
    table {
        border: 1px solid black !important;
    }
    td {
        border: 1px solid black !important;
    }
}
</style>